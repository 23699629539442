/* ========================================================================
     Component: orders
 ========================================================================== */

.order-check {
  font-family: "Brown-Regular", sans-serif;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  position: relative;
  padding: 4px 8px 4px 30px;
  margin: 2px;
  border-radius: 4px;
}

.order-check.success {
  background-color: #f0fde7;
  color: #4fa433;
}

.order-check.fail {
  background-color: #fde8e7;
  color: #e05c57;
}

.safello-share-value {
  font-family: "BuenosAires-Regular", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #34b094;
}

.partner-share-value {
  font-family: "BuenosAires-Regular", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1285e5;
}

.timeline_time {
  font-family: "BuenosAires-Light", sans-serif;
  font-size: 13px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.4);
}

.timeline_title {
  font-family: "BuenosAires-Light", sans-serif;
  font-size: 16px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.6);
}

.timeline_content_title {
  font-family: "BuenosAires-Light", sans-serif;
  font-size: 16px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.6);
  margin-top: 0.75em;
  margin-bottom: 0.75em;
}


