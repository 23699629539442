.bankid {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 60px;
  &__logo {
    height: 92px;
    width: 92px;
    display: block;
    margin: 60px auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    color: white;
  }
  &__info-text {
    margin-bottom: 20px;
  }
}

.divider {
  width: 120%;
  margin-top: 10px;
  margin-bottom: 10px;
  // compensate for container padding
  margin-left: -20px;
  height: 0px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.positive {
  color: #27c09f;
}
.negative {
  color: #ff6868;
}
