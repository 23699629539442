div.grid-no-v-scroll .react-grid-Canvas {
  overflow: hidden !important;
  overflow-x: auto !important;
}

div.grid-no-v-scroll .react-grid-Container {
  padding-right: 23px;
}

div.grid-no-border .react-grid-Grid {
  border: 0;
}

