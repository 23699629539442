/* ========================================================================
     Component: orders
 ========================================================================== */

.comment-header {
  font-family: "Brown-Regular", sans-serif;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.6);
}

.comment-timestamp {
  font-family: "Brown-Light", sans-serif;
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: rgba(0, 0, 0, 0.5);
}

.comment-add-text {
  font-family: "Brown-Regular", sans-serif;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.5);
  border: 0;
  min-height: 50px;
  overflow: hidden;
  resize: none;
}

.comment-add-text:focus {
  color: rgba(0, 0, 0, 0.5) !important;
}

.comment-text {
  font-family: "Brown-Regular", sans-serif;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.5);
  border: 0;
  background-color: white !important;
}


