/* ========================================================================
     Component: orders
 ========================================================================== */
/* google docs copy table starts */
.table-copy {
  display: none;
  border-collapse: collapse;
  font-size: 13px;
  color: black;
  min-width: 465px;
}
.table-copy td, th {
  border-width: 1px;
  border-color: #ccc;
  border-style: solid;
  padding: 2px;
  min-width: 150px;
}

/* google docs copy table ends */

/* chart */
.chart-tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}
.chart-tooltip .chart-tooltip-text {
  visibility: hidden;
  min-width: 400px;
  text-align: center;
  position: absolute;
  padding: 5px;
  margin-left: 5px;
  font-size: 12px;
  border-radius: 2px;
  border-color: transparent;
  background-color: rgba(0, 0, 0, 0.75);
  color: #f1f1f1;
  z-index: 5;
}
.chart-tooltip:hover .chart-tooltip-text {
  visibility: visible;
}
/* chart ends */

.header-area {
  font-family: "BuenosAires-Light", sans-serif;
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(77, 93, 90, 0.6);
}

.header-area span {
  font-family: "BuenosAires-Regular", sans-serif;
  font-weight: normal;
  color: rgba(77, 93, 90, 0.85);
}

.card-area {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 0.25rem;
  margin-top: 0.85rem;
  margin-bottom: 0.85rem;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}

.card-section-header {
  font-family: "BuenosAires-Bold", sans-serif;
  font-size: 15px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.3);
  text-transform: uppercase;
}

.card-section-divider {
  border-bottom: 2px dashed #eee;
}

.validation-image {
  border-radius: 50%;
  position: relative;
  width: 20px;
  height: 20px;
}

.validation-image > img {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  top: 6px;
  left: 0;
  right: 0;
}

.details-row {
  margin-bottom: 0.65rem;
}

.details-header {
  font-family: "Brown-Regular", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.85);
  margin-right: 10px;
}

.details-value {
  font-family: "Brown-Light", sans-serif;
  font-size: 16px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.6);
}

.action_button {
  width: 100%;
  height: 40px;
  border-radius: 2px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.04);
  position: relative;
  text-align: center;
  color: white;
  font-family: "BuenosAires-Regular", sans-serif;
  font-size: 16px;
}

.action_button > i {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.action_button:hover,
.action_button:active {
  color: white !important;
}

.comment-header {
  font-family: "Brown-Regular", sans-serif;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.6);
}

.comment-timestamp {
  font-family: "Brown-Light", sans-serif;
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: rgba(0, 0, 0, 0.5);
}

.comment-add-text {
  font-family: "Brown-Regular", sans-serif;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.5);
  border: 0;
  min-height: 50px;
  overflow: hidden;
  resize: none;
}

.comment-add-text:focus {
  color: rgba(0, 0, 0, 0.5) !important;
}

.comment-text {
  font-family: "Brown-Regular", sans-serif;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.5);
  border: 0;
  background-color: white !important;
}

.status-badge {
  font-family: "Brown-Regular", sans-serif;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  position: relative;
  padding: 4px 8px 4px 30px;
  margin: 2px;
  border-radius: 4px;
  display: inline-block;
}

.wallet-badge {
  font-family: "Brown-Regular", sans-serif;
  font-size: 12px;
  font-weight: 400;
  padding: 4px 8px 4px 8px;
  margin: 2px;
  border-radius: 4px;
  display: inline-block;
  background-color: #3bb478;
  color: white;
}

.flagged-badge {
  font-family: "Brown-Regular", sans-serif;
  font-size: 12px;
  font-weight: 400;
  padding: 4px 8px 4px 8px;
  margin: 2px;
  border-radius: 4px;
  display: inline-block;
  background-color: #e05c57;
  color: white;
}

.tag-badge {
  font-family: "Brown-Regular", sans-serif;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  position: relative;
  padding: 4px 8px 4px 8px;
  margin: 2px;
  border-radius: 4px;
  display: inline-block;
}

.status-badge > img {
  width: 16px;
  height: 12px;
  margin-right: 6px;
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
}

.safello-font {
  font-family: "Brown-Regular", sans-serif;
}

.react-grid-HeaderCell {
  padding-left: 4px;
  padding-right: 4px;
  font-size: 0.8rem;
  text-align: center;
}
.react-grid-Cell {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  font-size: 13px;
  user-select: text;
  padding-left: 4px;
  padding-right: 4px;
}
.react-grid-Row--even .react-grid-Cell {
  background-color: #f9f9f9;
}
.status-badge,
.tag-badge {
  font-size: 13px;
}
.text-right{
  text-align: right;
}
.text-bold{
  font-weight: bold;
}

@media only screen and (max-width: 1280px) {
  .react-grid-HeaderCell {
    padding-left: 2px;
    padding-right: 2px;
    font-size: 0.75rem;
  }
  .react-grid-Cell {
    height: 20px;
    font-size: 12px;
    padding-left: 2px;
    padding-right: 2px;
    text-align: center;
  }
  .status-badge,
  .tag-badge {
    font-size: 10px;
  }
}

.tab-content {
  padding: 0;
  border-style: none;
  border-width: 0;
  border-color: $gray;
  .nav-pills + & {
    border: 0;
    padding: 0;
  }
  .p-0 & {
    padding: 0 !important;
  }
}
.nav-tabs .nav-link {
  cursor: pointer;
  padding: 5px !important;
}
