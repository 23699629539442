/* ========================================================================
     Component: customer
 ========================================================================== */

.customer-name {
  height: 22px;
  font-family: "Brown-Regular", sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.8);
}

.customer-name > a {
  color: #1b72e2;
}

.customer-risk-profile-container {
  height: 24px;
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 2px;
  background-color: #ffbf6a;
  text-align: center;
}

.customer-risk-profile-text {
  font-family: "Brown-Regular", sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}

.customer-contact-details-header {
  font-family: "Brown-Regular", sans-serif;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.85);
}

.customer-order-container {
  display: flex;
  height: 30px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 2px;
  background-color: #ffa133;
  margin: auto;
}

.customer-recurring-container {
  display: flex;
  flex-flow: column;
  margin: 25px 0px;
}

.customer-order-container b {
  margin: auto;
  text-align: center;
  color: white;
}

.customer-order-container-fiat {
  font-size: 40px;
}

.customer-order-container-currency {
  font-size: 20px;
}
